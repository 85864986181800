.IntroBanner {
  position: absolute;
  bottom: 0%;
  left: 0%;
  margin-left: 50px;
  margin-bottom: 50px;
  background-color: rgba(255, 255, 255, 0);
  z-index: 100;
  font-size: 1em;
  font-family: 'Cutive Mono', monospace;
}
.IntroBanner > p {
  margin: 0px;
  padding-bottom: 5px;
}
.fadeOutBanner{
  opacity: 0;
  transition: opacity 3s 2s;
}
.controls {
  opacity: 0;
  padding-top: 15px;
  transition: opacity 3s 0s;
}
.controls > p {
  margin: 0px;
  padding-bottom: 5px;
}
.fadeInControls{
  opacity: 1;
  transition: opacity 3s 0s;
}