.App {
  width: calc(100% - 2rem);
  height: calc(100% - 2rem);
  border: 1rem solid;
  z-index: 1000;
}
.curtain {
  position: fixed;
  width: calc(100% - 2rem);
  height: calc(100% - 2rem);
  background-color: rgba(255, 255, 255, 1);
  z-index: 10;
}
.fadeOut {
  opacity: 0;
  transition: opacity 3s;
  transition-timing-function: cubic-bezier(0.22, 0, 1, 1);
}