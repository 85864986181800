.PictureBanner {
  font-family: 'Cutive Mono', monospace;
}

.DesktopPictureBanner {
  position: absolute;
  width: 25vw;
  height: 60vh;
  background-color: rgba(255, 255, 255, 0.9);
  cursor: default;
  border-right: 1rem solid;
  border-bottom: 1rem solid;
  z-index: 100;
}
.DesktopPictureBanner > a {
  text-decoration: none;
}
.DesktopPictureBanner > img {
  padding-top: 15px;
  position: absolute;
  height: 75%;
  width: 100%;
  object-fit: contain;
  left: 0%;
}
.DesktopPictureBanner:hover {
  font-weight: bold;
}
.DesktopPictureBanner .info {
  position: absolute;
  height: 20%;
  top: 80%;
  width: 100%;

}
.DesktopPictureBanner .info > h1 {
  position: absolute;
  padding-left: 40px;
  padding-bottom: 20px;
  margin: 0;
  letter-spacing: 3px;
  font-size: 1.5em;
  bottom: 0;
}

.MobilePictureBanner {
  position: absolute;
  width: 45vw;
  height: 35vh;
  background-color: rgba(255, 255, 255, 0.9);
  cursor: default;
  border-right: 1rem solid;
  border-bottom: 1rem solid;
  z-index: 100;
}

.MobilePictureBanner > a {
  text-decoration: none;
}
.MobilePictureBanner > img {
  padding-top: 15px;
  position: absolute;
  height: 75%;
  width: 100%;
  object-fit: contain;
  left: 0%;
}

.MobilePictureBanner .info {
  position: absolute;
  height: 20%;
  top: 80%;
  width: 100%;
}
.MobilePictureBanner .info > h1 {
  position: absolute;
  letter-spacing: 3px;
  font-size: 1em;
  padding-left: 20px;
  padding-bottom: 20px;
  margin: 0;
  bottom: 0;
}